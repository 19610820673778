'use strict'

###*
 # @ngdoc service
 # @name lpaData.factory:StatusManager

 # @description

###
angular
  .module 'lpaData'
  .factory 'StatusManager', [
    'Restangular'
    '_'
    'MundoDefaultManager'
    (
      Restangular
      _
      MundoDefaultManager
    )->
      StatusManagerBase = new MundoDefaultManager()

      StatusManagerBase.conf.url = 'statuses'

      StatusManagerBase.getListSchema = ->
        [
          key: 'id'
          title: 'id'
          sort: 'id'
        ,
          key: 'label'
          title: 'Label'
          sort: 'label'
        ]

      StatusManagerBase
  ]
